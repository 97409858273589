import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import LogoEffect1 from "../components/Logoeffectbox/LogoEffect1";
import LogoEffect2 from "../components/Logoeffectbox/LogoEffect2";
import Dots from "../components/dots-animation/Dots";
import FarmingStaking from "../components/farming-staking";
import Blockchains from "../components/blockchains";
import Launchbutton from "../components/buttons/Launchbutton";
import Whitepaperbutton from "../components/buttons/Whitepaperbutton";
import Roadmap from "../components/roadmap";
import Community from "../components/community";
import Stats from "../components/stats";
import Contact from "../components/contact";
import Counter from "../components/counter-box";

const Home = () => {

    // const THREE_DAYS_IN_MS = 4 * 24 * 60 * 60 * 1000;
    let today = new Date(),
    yyyy = today.getFullYear()
    let dayMonth = "05/03/"
    let time = "07:30:00"
    let saleTime = dayMonth + yyyy + " " +time+" GMT+05:30";

    const countDown = new Date(saleTime).getTime()
    const dateTimeAfterThreeDays = countDown;
    
    return (
        <>
            <section className="md:h-screen py-24 px-4 relative overflow-hidden">
                <div className="container mx-auto">
                    <div className="md:w-96 md:h-96 w-56 h-56 mx-auto">
                        <LogoEffect1/>
                    </div> 
                    <div className="md:w-3/4 md:h-96 w-full h-auto mx-auto mt-20 md:mt-10 relative z-20">
                        <h1 className="text-3xl md:text-5xl text-center text-white font-semibold leading-10 md:leading-tight tracking-wider">
                            <Typewriter
                                onInit={(typewriter)=> {
                                    typewriter
                                    .typeString("Welcome to Metaple")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("The World’s First Multi Staking Protocol")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("Reinventing Decentralised Finance on Binance Smart Chain")
                                    .start(100);
                                }}
                            />
                        </h1>
                    </div>
                </div> 
                <div className='hidden md:block hand-left absolute left-6 bottom-12 md:bottom-6'>
                    <img src="images/global/hand-left.png" className="max-w-full w-40 lg:w-3/4" alt="Left Hand" /> 
                </div>
                <div className='hidden md:block hand-left absolute right-6 bottom-12 md:bottom-6'>
                    <img src="images/global/hand-right.png" className="max-w-full w-40 lg:w-3/4 mr-0 ml-auto" alt="Right Hand" /> 
                </div>
                <a href='#metaple-hero' className='down-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 animate-pulse z-20'>
                    <img src="images/svg/down-double-arrow.svg" className="max-w-full w-1/2 mx-auto" /> 
                </a>
            </section>

            <section className="seed-sale-section pt-16 md:pt-32 px-4">
                <div className="container mx-auto xl:w-2/4">
                    <Counter targetDate={dateTimeAfterThreeDays} />
                </div>
            </section>

            <section className='relative pt-16 pb-8 md:py-32 px-4 overflow-hidden' id="metaple-hero">
                <img src="images/svg/metaple-analysis-bg.svg" className="w-full absolute left-0 top-32" alt="Metaple Analysis Background" />
                <LogoEffect2/>
                {/* <div className="text-2xl text-sky-300 text-center mb-5 tracking-wide animate-pulse">Seed Sale is Live Now!</div> */}
                <div className='text-4xl text-center text-white font-semibold leading-tight tracking-wider mb-14 relative'>Let’s Multiple & Maximize with Metaple</div>
                <div class="flex items-center flex-col md:flex-row justify-center relative space-y-3 md:space-y-0 z-20">
                    <a className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#F81C8E] mx-1 md:mx-2 transition duration-200" href="https://blog.metaple.finance/" target={`_blank`}><i className="lab la-blogger-b"></i></a>
                    <Launchbutton/>
                    <Whitepaperbutton/>
                    <a target={`_blank`} href="https://bit.ly/MetapleListing" className="inline-block px-8 md:px-12 py-4 text-white font-medium bg-transparent border-2 border-white hover:bg-white hover:text-black rounded-full transition duration-200 mx-3">Launchpad Form</a>
                    <a className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#229ED9] mx-1 md:mx-2 transition duration-200" href="https://t.me/Metaple_Listing" target={`_blank`}><i className="lab la-telegram"></i></a>
                </div>
            </section>

            <section className="py-10 md:py-20 relative px-4">
                <div className="container mx-auto relative z-10">
                    <div className="px-10 md:px-24 py-10 md:py-32 rounded-3xl md:rounded-[150px] bg-[#23F6F6] bg-opacity-0 shadow-effect backdrop-blur-lg">
                        <div className="grid grid-cols-1 md:grid-cols-12">
                            <div className="col-span-1 md:col-span-7 flex items-center">
                                <div className="content-box">
                                    <div className="text-xl md:text-3xl text-white font-semibold mb-8 md:mb-14 tracking-wider">One Stop for Multiple Earning</div>
                                    <div className="w-full">
                                        <ul className="space-y-12">
                                            <li className="flex -mx-4">
                                                <div className="md:px-4 pr-3">
                                                <span className="flex w-10 h-10 mx-auto items-center justify-center text-lg font-medium font-heading rounded-full bg-[#DF3399] text-white">1</span>
                                                </div>
                                                <div className="md:px-4">
                                                    <p className="text-white leading-loose text-sm">An open and global financial system built for the internet age – an alternative to a system that’s opaque, tightly controlled, and held together by decades-old infrastructure and processes.</p>
                                                </div>
                                            </li>
                                            <li className="flex -mx-4">
                                                <div className="md:px-4 pr-3">
                                                <span className="flex w-10 h-10 mx-auto items-center justify-center text-lg font-medium font-heading rounded-full bg-[#DF3399] text-white">2</span>
                                                </div>
                                                <div className="md:px-4">
                                                    <p className="text-white leading-loose text-sm">Metaple brings numerous benefits when compared to traditional financial services. Distributed ledger containing information about all the activities that have taken place on a blockchain network is shared by everyone.</p>
                                                </div>
                                            </li>
                                            <li className="flex -mx-4">
                                                <div className="md:px-4 pr-3">
                                                <span className="flex w-10 h-10 mx-auto items-center justify-center text-lg font-medium font-heading rounded-full bg-[#DF3399] text-white">3</span>
                                                </div>
                                                <div className="md:px-4">
                                                    <p className="text-white leading-loose text-sm">Through the use of smart contracts and distributed systems, deploying a financial application or product becomes much less complex and secure</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 md:col-span-5">
                                <img src="images/global/multiple-earning.svg" className="w-auto mx-auto" alt="Multiple Earning" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-bg absolute bottom-0 left-0">
                    <img src="images/global/multipe-earning-bg.png" className="w-full mx-full animate-pulse" alt="Multiple Earning Background" />
                </div>
            </section>

            <Blockchains/>

            <section className="mojo-section px-4 hidden">
                <div className="container mx-auto relative z-10">
                    <div className="mojo-logo mb-14">
                        <img src="images/global/metamojo-logo.png" className="w-40 mx-auto mb-8 animation-bounce" alt="Metamojo Logo" />
                        <img src="images/global/metamojo-logo-text.png" className="w-60 mx-auto" alt="Metamojo Logo" />
                        <p className="text-center text-white font-semibold text-xs tracking-wide">powered by Metaple</p>
                    </div>
                    <div className='text-4xl text-center text-white font-semibold leading-tight tracking-wider mb-14 relative'>Leading Play to Earn Crypto Platform! </div>
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-8">
                        <div className="cols-span-1">
                            <div className="overflow-hidden">
                                <div className="game-banner mb-6">
                                    <img src="images/global/american-roulette.png" className="w-60 mx-auto" alt="American Roulette" />
                                </div>
                                <a href="https://metamojo.ai/games/american-roulette" target={`_blank`} className="game-name block text-center text-xl text-white tracking-wider hover:text-[#F81C8E]">American Roulette</a>
                            </div>
                        </div>
                        <div className="cols-span-1">
                            <div className="overflow-hidden">
                                <div className="game-banner mb-6">
                                    <img src="images/global/blackjack.png" className="w-60 mx-auto" alt="Blackjack" />
                                </div>
                                <a href="https://metamojo.ai/games/blackjack" target={`_blank`} className="game-name block text-center text-xl text-white tracking-wider hover:text-[#F81C8E]">Blackjack</a>
                            </div>
                        </div>
                        <div className="cols-span-1">
                            <div className="overflow-hidden">
                                <div className="game-banner mb-6">
                                    <img src="images/global/heads-or-tails.png" className="w-60 mx-auto" alt="Heads or Tails" />
                                </div>
                                <a href="https://metamojo.ai/games/heads-or-tails" target={`_blank`} className="game-name block text-center text-xl text-white tracking-wider hover:text-[#F81C8E]">Heads or Tails</a>
                            </div>
                        </div>
                        <div className="cols-span-1">
                            <div className="overflow-hidden">
                                <div className="game-banner mb-6">
                                    <img src="images/global/horse-racing.png" className="w-60 mx-auto" alt="Horse Racing" />
                                </div>
                                <a href="https://metamojo.ai/games/horse-racing" target={`_blank`} className="game-name block text-center text-xl text-white tracking-wider hover:text-[#F81C8E]">Horse Racing</a>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center flex-col md:flex-row justify-center relative space-y-3 md:space-y-0 z-20 mt-10">
                        <a target={`_blank`} href="https://metamojo.ai/" class="inline-block px-8 md:px-12 py-4 text-white font-medium bg-transparent border-2 border-white hover:bg-white hover:text-black rounded-full transition duration-200 mx-3">Play Now</a>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-20 relative overflow-hidden px-4">
                <div className="section-bg w-full absolute -top-80 left-0">
                    <img src="images/global/background-blockchain.png" className="w-full mx-full transform rotate-[-17deg] animate-pulse" alt="Multiple Earning Background" />
                </div>
                <div className="container mx-auto relative z-10">
                    <div className="px-10 md:px-24 py-10 md:py-32 rounded-3xl md:rounded-[150px] bg-[#DF3399] bg-opacity-0 shadow-effect-3 backdrop-blur-lg">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
                            <div className="col-span-1 md:col-span-5 order-2 md:order-1">
                                <img src="images/global/ecosystem-image.png" className="w-auto mx-auto spin-slow" alt="Ecosystem" />
                            </div>
                            <div className="col-span-1 md:col-span-7 flex items-center order-1 md:order-1">
                                <div className="content-box">
                                    <div className="text-xl md:text-3xl text-white font-semibold mb-6 md:mb-14 tracking-wider leading-8 md:leading-[50px]">An ecosystem of financial applications that is built on top of blockchain networks.</div>
                                    <p className="text-white leading-loose">An open-source, permissionless, and transparent financial service ecosystem that is available to everyone and operates without any central authority. The users would maintain full control over their assets and interact with this ecosystem through peer-to-peer (P2P), decentralized applications (dapps).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FarmingStaking/>

            <section className="py-10 md:py-20 relative overflow-hidden px-4">
                <div className="container mx-auto relative z-10">
                    <div className="px-10 md:px-24 py-14 md:py-32 rounded-3xl md:rounded-[150px] bg-[#23F6F6] bg-opacity-0 shadow-effect backdrop-blur-lg relative z-10">
                        <div className="w-full md:w-10/12 mx-auto text-center">
                            <img src="images/logo/metaple-logo-v3.png" className="w-auto mx-auto md:mb-28 mb-10" alt="Multiple Earning" />
                            <div className="text-2xl text-white font-medium tracking-wide mb-5">We do not need any intermediaries or arbitrators.</div>
                            <div className="text-sm text-white leading-7">The code specifies the resolution of every possible dispute, and the users maintain control over their funds at all times. This reduces the costs associated with providing and using these products and allows for a more frictionless financial system.</div>
                        </div>
                    </div>
                    <div className="dots-animation absolute -top-44 left-0 w-full">
                        <Dots/>
                    </div>
                </div>
                <div className="background-shape-left absolute top-1/2 -left-28 transform -translate-y-1/2 z-10">
                    <img src="images/global/left-side-shape.png" className="w-auto h-32 md:h-full animate-pulse" alt="Farming & Staking" />
                </div>
                <div className="background-shape-right absolute top-1/2 -right-28 transform -translate-y-1/2 z-10">
                    <img src="images/global/right-side-shape.png" className="w-auto h-32 md:h-full animate-pulse" alt="Farming & Staking" />
                </div>
            </section>

            <section className="py-0 md:py-20 relative overflow-hidden px-4">
                <div className="container mx-auto relative z-10 overflow-visible">
                    <div className="text-2xl md:text-5xl text-white font-semibold text-center md:text-left mb-6 md:mb-14 tracking-wider leading-tight md:leading-[50px]">Our Roadmap</div>
                    <Roadmap/>
                </div>
            </section>

            <section className="graph-section py-10 md:py-20 relative overflow-hidden px-4">
                <div className="container mx-auto relative z-10">
                    <div className="grid grid-cols-1 md:grid-cols-10 gap-8 md:gap-12 xl:w-11/12 mx-auto">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-2xl md:text-5xl text-white font-semibold text-center mb-6 md:mb-14 tracking-wider leading-tight md:leading-[50px]">Tokenomics</div>
                            <img src="images/global/tokenomics-graph-light.png" className="w-auto lg:w-5/6 mx-auto" alt="Metaple Graph" />
                            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="col-span-1">
                                    <div className="bg-gray-900 bg-opacity-30 shadow-inner px-6 py-10 rounded-2xl">
                                        <img src="images/global/chart-1.png" className="w-auto mx-auto mb-10" alt="Farming & Staking" />
                                        <div className="map-content text-sm md:text-lg font-medium text-white text-center tracking-wider">
                                            <div className="mb-4">7% - Founder</div>
                                            <div className="mb-4">20% - Public sales</div>
                                            <div className="mb-4">10% - CEX & DEX Liquidity</div>
                                            <div className="">40% - Farming & Staking </div>
                                        </div>
                                    </div>
                                </div>        
                                <div className="col-span-1 md:mt-36">
                                    <div className="bg-gray-900 bg-opacity-30 shadow-inner p-6 rounded-2xl">
                                        <img src="images/global/chart-2.png" className="w-auto mx-auto mb-10" alt="Farming & Staking" />
                                        <div className="map-content text-sm md:text-lg font-medium text-white text-center tracking-wider">
                                            <div className="mb-4">5% -Advisory</div>
                                            <div className="mb-4">5% - Marketing</div>
                                            <div className="mb-4">5% - Private sales</div>
                                            <div className="mb-4">5% - Development</div>
                                            <div className="">3% -Emergency Funds</div>
                                        </div>
                                    </div>
                                </div>        
                            </div> */}
                        </div>
                        <div className="col-span-1 md:col-span-4">
                            <div className="grid grid-cols-2 gap-3">
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Chain Network</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">BSC</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Token Name</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Metaple</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Token Ticker</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">MLX</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Sale Quantity</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">37,500,000 MLX</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Initial Supply</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">150,000,000 MLX</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Supply For Game</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">2,000,000 MLX</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">New Token emissions</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">0.16 per block</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Fair Launch</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Yes</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">BSC Chain</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">
                                        <a
                                            target={`_blank`}
                                            href="https://bscscan.com/token/0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6"
                                        >
                                            0xeBD...315d6
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Community/>

            <Stats/>

            <section className="partners-section py-14 md:py-20 px-4">
                <div className="container mx-auto relative z-10">
                    <div class="text-2xl md:text-5xl text-white font-semibold text-center mb-6 md:mb-14 tracking-wider leading-tight md:leading-[50px]">Partners With</div>
                    <div className="flex items-center justify-center space-x-3 md:space-x-6">
                        <div className="partner-box w-1/3 md:w-1/6 bg-[#0BE9F0] bg-opacity-90 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4">
                            <img src="images/logo/pinkswap.png" className="w-auto h-8 md:h-14 mx-auto mb-3 md:mb-5"></img>
                            <div class="text-lg md:text-xl text-white text-center tracking-wide">PinkSale</div>
                        </div>
                        <div className="partner-box w-1/3 md:w-1/6 bg-[#0BE9F0] bg-opacity-90 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4">
                            <img src="images/logo/bitmart.png" className="w-auto h-8 md:h-14 mx-auto mb-3 md:mb-5"></img>
                            <div class="text-lg md:text-xl text-white text-center tracking-wide">BitMart</div>
                        </div>
                        <div className="partner-box w-1/3 md:w-1/6 bg-[#0BE9F0] bg-opacity-90 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4">
                            <img src="images/logo/dx-logo.png" className="w-auto h-8 md:h-14 mx-auto mb-3 md:mb-5"></img>
                            <div class="text-lg md:text-xl text-white text-center tracking-wide">DX Sale</div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Contact/>
        </>
    );
};

export default Home;