const Blockchains = () => {
    return ( 
    <>
        <section className="py-0 md:py-20 relative px-4">
            <div className="container mx-auto relative z-10">
                <div className="px-4 md:px-8 py-8 md:py-12 rounded-3xl md:rounded-[150px] bg-[#23F6F6] bg-opacity-0 shadow-effect-2 backdrop-blur-lg">
                    <div className="text-xl md:text-3xl text-white font-semibold mb-8 md:mb-14 tracking-wider text-center mb-8">Integrated Blockchains</div>
                    <img src="images/global/binance_logo.png" className="w-auto mx-auto h-10 md:h-16" alt="Multiple Earning Background" />
                </div>
            </div>
        </section>
    </>
    )
};
export default Blockchains;