const NoPage = () => {
    return(
        <>
            <section className="h-screen overflow-hidden flex items-center justify-center">
                <div className="404-box text-center">
                    <div className="text-6xl text-white text-center mb-6">404</div>
                    <div className="text-3xl text-white text-center mb-4">We can's find that page</div>
                    <a class="inline-block px-6 md:px-8 py-3 text-white font-medium bg-transparent border-2 border-[#F81C8E] hover:bg-[#F81C8E] hover:text-white rounded-full transition duration-200 mx-auto mt-5" href="/">Back to Home</a>
                </div>
            </section>
        </>
    )
};

export default NoPage;