import "jquery-nice-select/css/nice-select.css";
import { useEffect, useRef, useState } from "react";
import $ from "jquery";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const Contact = () =>{
    const selectRef = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [notify, setNotify] = useState("");

    let handleSubmit = async (e) => {
        
        e.preventDefault();
        try {
            let res = await fetch("https://360coreinc.com/test.php?name="+name+"&email="+email+"&message="+message, {
                method: "GET",
                mode: 'no-cors',
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setName("");
                setEmail("");
                setMessage("");
                setNotify("Message Sent Successfully.");
                e.target.reset();
            } else {
                setNotify("Some error occured");
            }
        } catch (err) {
            console.log(err);
            setNotify("Message Sent Successfully.");
            e.target.reset();
            // setNotify("Request not sent due to "+err);
        }

    };
    
    useEffect(() => {
        $(selectRef.current).niceSelect();
    }, []);

    const getItem = (e) => {
        e.preventDefault();
        let selected = $(selectRef.current).val();
        alert(` You have selected ${selected}`);
    };

    return(
        <>
            <section className="graph-section pt-20 pb-12 md:pb-20 relative overflow-hidden">
                <img src="images/global/contact-bg.png" className="w-full h-full object-cover mx-auto absolute top-0 left-1/5 transform translate-x-1/5" alt="Contact Background" />
                <div className="container mx-auto relative z-10 mb-0 px-4">
                    <div className="text-3xl text-white font-semibold mb-8 md:mb-14 tracking-wider text-center">Contact Us Today!</div>
                    <div className="w-full lg:w-2/4 pt-0 md:pt-32 pb-8 md:pb-24 relative mx-auto">
                        <p className="text-white text-center mb-3">{notify ? <p>{notify}</p> : null}</p>
                        <form className="w-full" onSubmit={handleSubmit}>
                            <input required onChange={(e) => setName(e.target.value)} className="mb-5 w-full px-8 py-4 bg-black font-medium placeholder-gray-300 placeholder-opacity-30 text-sm tracking-wider text-white rounded-full focus:outline-none" type="text" placeholder="Name" />
                            <input required onChange={(e) => setEmail(e.target.value)} className="mb-5 w-full px-8 py-4 bg-black font-medium placeholder-gray-300 placeholder-opacity-30 text-sm tracking-wider text-white rounded-full focus:outline-none" type="email" placeholder="example@xyz.com" />
                            <textarea required onChange={(e) => setMessage(e.target.value)} className="mb-5 w-full h-28 md:h-auto px-8 py-4 bg-black font-medium placeholder-gray-300 placeholder-opacity-30 text-sm tracking-wider text-white rounded-3xl focus:outline-none" name id cols={30} rows={10} placeholder="Your message" defaultValue={""} />
                            <button type="submit" className="py-4 px-12 text-white font-medium tracking-wide bg-[#B74192] hover:bg-white hover:text-black rounded-full transition duration-200 mt-4 md:mt-14 mx-auto table">Send message</button>
                        </form>
                    </div>
                </div>
                <div className="contact-details relative z-10 mt-14 md:mt-24">
                    <div className="mail-box text-white text-sm md:text-lg text-center mb-4">
                        <a href="mailto:hello@metaple.finance" className="hover:text-[#0BE9F0] transition duration-200">Mail us : hello@metaple.finance</a>
                    </div>
                    <div className="flex justify-center items-center">
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#00acee] mx-1 md:mx-2 transition duration-200"
                            href="https://twitter.com/MetapleFinance/"
                            target={`_blank`}
                        >
                            <i className="lab la-twitter"></i>
                        </a>
                        {/* <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#FF1000] mx-1 md:mx-2 transition duration-200"
                            href="https://www.reddit.com/r/Metaple/"
                            target={`_blank`}
                        >
                            <i className="lab la-reddit"></i>
                        </a> */}
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#FF0000] mx-1 md:mx-2 transition duration-200"
                            href="https://www.youtube.com/channel/UCPwpi8vXIGDE6eZbV4gXRdQ"
                            target={`_blank`}
                        >
                            <i className="lab la-youtube"></i>
                        </a>
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#66cdaa] mx-1 md:mx-2 transition duration-200"
                            href="https://metaplefinance.medium.com/"
                            target={`_blank`}
                        >
                            <i className="lab la-medium-m"></i>
                        </a>
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#171515] mx-1 md:mx-2 transition duration-200"
                            href="https://github.com/MetapleFinance"
                            target={`_blank`}
                        >
                            <i className="lab la-github"></i>
                        </a>
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#229ED9] mx-1 md:mx-2 transition duration-200"
                            href="https://t.me/MetapleFinance"
                            target={`_blank`}
                        >
                            <i className="lab la-telegram"></i>
                        </a>
                        <a
                            className="inline-flex items-center justify-center w-8 h-8 md:w-12 md:h-12 rounded-full bg-white text-lg md:text-2xl text-black hover:text-white hover:bg-[#229ED9] mx-1 md:mx-2 transition duration-200"
                            href="https://www.facebook.com/TheMetapleFinance"
                            target={`_blank`}
                        >
                            <i className="lab la-facebook-f"></i>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Contact;