import React, { useState, useEffect } from "react";

const LogoEffect2 = () =>{
    const [scrollPosition, setScrollPosition] = useState('70');
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    function moveCircle() {
        const speed = 5
        const scrolltop = window.pageYOffset
        const scrollAndSpeed = scrolltop / speed
    
        // console.log(scrollAndSpeed.toString())
        setScrollPosition(scrollAndSpeed.toString())
    };
    useEffect(() => {
        window.addEventListener(
          'scroll',
          function () {
            requestAnimationFrame(moveCircle)
          },
          false
        )
    }, []);
    const rotateStyles = { 
        transform: `rotate(${scrollPosition-1000}deg)` 
    };
    return(
        <>
            <div className='relative w-auto mx-auto text-center mb-14'>
                <img src="images/logo/metaple-logo-v2-back.png" className="w-full md:w-auto mx-auto" style={rotateStyles} alt="Metaple Logo Background" />
                <img src="images/logo/metaple-logo-v2.png" className="w-40 md:w-60 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse" alt="Metaple Logo" />
            </div>
        </>
    )
};
export default LogoEffect2;