const Whitepaperbutton = () =>{
    return(
        <>
            <a
                class="inline-block px-8 md:px-12 py-4 text-white font-medium bg-transparent border-2 border-[#F81C8E] hover:bg-[#F81C8E] hover:text-white rounded-full transition duration-200 mx-3"
                href="https://docs.metaple.finance"
                target={`_blank`}
            >
                Whitepaper
            </a>
        </>
    )
}

export default Whitepaperbutton;