const Stats = () => {
    return ( 
    <>
        <section className="py-14 md:py-20 relative px-4">
            <div className="container mx-auto relative z-10">
                <div className="text-3xl md:text-5xl text-white font-semibold mb-6 md:mb-14 tracking-wider text-center">Statistical Data</div>
                <div className="px-4 md:px-8 py-8 md:py-24 rounded-3xl lg:rounded-[150px] bg-[#23F6F6] bg-opacity-0 shadow-effect-2 backdrop-blur-lg">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-9">
                        <div className="col-span-1">
                            <div className="text-2xl md:text-5xl text-white font-semibold mb-2 md:mb-4 tracking-wider text-center">0 MLX</div>
                            <div className="text-lg md:text-2xl text-white font-semibold tracking-wider text-center">Total Supply</div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-2xl md:text-5xl text-white font-semibold mb-2 md:mb-4 tracking-wider text-center">$ 0</div>
                            <div className="text-lg md:text-2xl text-white font-semibold tracking-wider text-center">Market Cap</div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-2xl md:text-5xl text-white font-semibold mb-2 md:mb-4 tracking-wider text-center">$ 0</div>
                            <div className="text-lg md:text-2xl text-white font-semibold tracking-wider text-center">Total Value Locked</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
};
export default Stats;