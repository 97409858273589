import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';

const ExpiredNotice = () => {
    return (
      <div className="expired-notice text-white text-center">
        {/*<span>Sale Is Live</span>
        <p>Buy now on metaple.finance</p>*/}
      </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter flex items-center justify-between text-center">
          <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </div>
    );
  };

const Counter = ({ targetDate }) =>{
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
      } else {
        return(
            <>
            <div className='text-4xl text-center text-white font-semibold leading-tight tracking-wider mb-10 relative'>Seed Sale Starts In</div>
            <div className="counter-box px-4 md:px-16 py-6 md:py-16 rounded-3xl md:rounded-[80px] bg-[#23F6F6] bg-opacity-0 shadow-effect backdrop-blur-lg text-white">
                <ShowCounter
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                />
            </div>
            </>   
        );
      }
};

export default Counter;