import '../dots-animation/dots.css';

const Dots = () => {
    return ( <>
            <div>
                {/* <div className="notice">Click on the screen to see the next animation</div> */}
                <input className="dots" id="pulse-animation" name="selection" type="radio" hidden/>
                <label className="shower" htmlFor="shower">Shower</label>
                <input className="dots" id="shower" name="selection" type="radio" hidden/>
                <label className="spread" htmlFor="spread">Spread</label>
                <input defaultChecked="checked" className="dots" id="spread" name="selection" type="radio" hidden/>
                <label className="pulse-animation" htmlFor="pulse-animation">Dots</label>
                <div className="wrapper">
                    <div className="container">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    </div>
                </div>
            </div>

            </>
        )
    };
    export default Dots;