
const FarmingStaking = () => {
    return ( 
    <>
        <section className="pt-8 md:py-20 relative overflow-hidden px-4">
            <div className="background-shape">
                <div className="background-shape-left">
                    <img src="images/global/farming-left-bg.png" className="w-auto absolute top-0 -left-32 animate-pulse" alt="Farming & Staking" />
                </div>
                <div className="background-shape-right">
                    <img src="images/global/farming-right-bg.png" className="w-auto absolute top-0 -right-32 animate-pulse" alt="Farming & Staking" />
                </div>
            </div>
            <div className="container mx-auto relative z-10">
                <div className="text-2xl md:text-5xl text-white font-semibold text-center mb-6 md:mb-14 tracking-wider leading-tight md:leading-[50px]">Farming & Staking </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/liquidity-pools-icon.png" className="w-auto mx-auto" alt="Liquidity Pools" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">Liquidity Pools</div>
                                <div className="text-[10px] text-white leading-5">Liquidity providers (LPs) add funds to liquidity pools. You could think of a liquidity pool as a big pile of funds that traders can trade against.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/staking-icon.png" className="w-auto mx-auto" alt="Staking" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">Staking</div>
                                <div className="text-[10px] text-white leading-5">A staking pool is a group of coin holders merging their resources to increase their chances of validating blocks and receiving rewards. They combine their staking power and share the rewards proportionally to their contributions to the pool.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/swapping-icon.png" className="w-auto mx-auto" alt="Swapping" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">Swapping</div>
                                <div className="text-[10px] text-white leading-5">Swap refers to exchanging one cryptocurrency you hold for the equivalent value of another cryptocurrency.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/amm-icon.png" className="w-auto mx-auto" alt="Swapping" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">AMM</div>
                                <div className="text-[10px] text-white leading-5">An automated market maker (AMM) is a type of decentralized exchange (DEX) protocol that relies on a mathematical formula to price assets. Instead of using an order book like a traditional exchange, assets are priced according to a pricing algorithm.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/farming-icon.png" className="w-auto mx-auto" alt="Swapping" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">Farming</div>
                                <div className="text-[10px] text-white leading-5">Farming is a way to make more crypto with your crypto. It involves you lending your funds to others through the magic of computer programs called smart contracts. In return for your service, you earn fees in the form of crypto.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="p-4 lg:p-6 xl:p-14 lg:w-80 xl:w-96 h-auto md:h-80 lg:h-80 xl:h-96 rounded-3xl xl:rounded-full bg-[#398FD9] bg-opacity-5 shadow-effect-4 backdrop-blur-lg flex items-center justify-center mx-auto">
                            <div className="content-box text-center">
                                <div className="img-box mb-6">
                                    <img src="images/icons/erc-20-icon.png" className="w-auto mx-auto" alt="Swapping" />
                                </div>
                                <div className="text-2xl text-white font-medium tracking-wide mb-3">ERC-20 Standard</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
};
export default FarmingStaking;