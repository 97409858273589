const LogoEffect1 = () =>{
    return(
        <>
            <div className="logobox relative text-center">
                <img src="images/logo/logo-bg-round.png" className="logo-round mx-auto" alt="Logo Background" /> 
                <img src="images/logo/metaple-logo.png" className="logo-main absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse" alt="Metaple Logo" />
                <img src="images/logo/metaple-logo-text.png" className="logo-main absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse" alt="Metaple Text Logo" />
            </div> 
        </>
    )
};
export default LogoEffect1;