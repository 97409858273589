import React from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";


const Roadmap = () =>{
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        // prevArrow:"<button type='button' class='slick-prev pull-left'><i className='las la-angle-right'></i></button>",
        // nextArrow:"<button type='button' class='slick-next pull-right'><i className='las la-angle-left'></i></button>",
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
              },
            },
            {

              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
              settings: "unslick"
            }
          ],
      };
    return(
        <>
            <Slider {...settings} className="roadmap-section slick-arrow -mx-4 overflow-visible before:content-[''] before:hidden md:before:block before:w-full before:h-1 before:bg-red-900 before:bg-opacity-20 before:absolute before:top-1/2 before:-translate-y-1/2">
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-green-600 bg-opacity-100 md:bg-opacity-80 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">V2 Swap</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 1</div>
                    </div>
                </div>
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 2</div>
                    </div>
                    <div className="single-section bg-green-600 bg-opacity-100 md:bg-opacity-80 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Crypto Games</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Decentralized Loans</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 3</div>
                    </div>
                </div>
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 4</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Decentralized Wallet</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Lottery</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 5</div>
                    </div>
                </div>                  
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 6</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Cross Chain Swapping</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Launchpad</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 7</div>
                    </div>
                </div>                  
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 8</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">NFT Marketplace</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">V3 of Metaple Swap</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 9</div>
                    </div>
                </div>                  
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 10</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Blockchain</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Decentralized Payment Gateway</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 11</div>
                    </div>
                </div>                  
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">Phase 12</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Future Trade (Decentralized).</div>
                    </div>
                </div>                  
                {/* <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Strategic and Fair Launchpad for new Projects</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">July 2022</div>
                    </div>
                </div>                  
                <div className="relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="md:h-44 md:flex items-end justify-center">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">August 2022</div>
                    </div>
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Android Wallet and IOS Wallet Development begins</div>
                    </div>
                </div>                  
                <div className="flex md:flex-col flex-col-reverse relative px-4 md:px-0 md:mx-4 mb-6 md:mb-0">
                    <div className="single-section bg-sky-900 bg-opacity-30 md:bg-opacity-20 rounded-lg md:rounded-3xl p-4 md:py-6 md:px-8 md:h-44 mb:mb-4 flex items-center md:justify-center">
                        <div className="text-sm tracking-wider text-white md:leading-8">Lottery Feature</div>
                    </div>
                    <div className="md:h-44">
                        <div className="text-sm md:text-xl font-medium md:font-semibold text-white mb-2 md:mb-0 text-left md:text-center">September 2022</div>
                    </div>
                </div>                   */}
            </Slider>
        </>
    )
};

export default Roadmap;