import { Outlet } from "react-router-dom";

const Layout = () => {
    return ( 
    <>

        <div className="lines">
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
            <div className="line" > </div> 
        </div>
        <main>
            <Outlet/>
        </main> 
        <footer className="text-center text-sm text-white tracking-wider py-6 md:py-8 border-t border-gray-500 border-opacity-30 mt-8">
            <span>© Metaple Finance {new Date().getFullYear()}</span>
        </footer>
    </>
    )
};

export default Layout;