const Launchbutton = () =>{
    return(
        <>
            {/* https://dapp.metaple.finance */}
            <a
                target={`_blank`}
                href="https://dapp.metaple.finance"
                class="inline-block px-8 md:px-12 py-4 text-white font-medium bg-transparent border-2 border-white hover:bg-white hover:text-black rounded-full transition duration-200 mx-3"
            >
                Launch App
            </a>
        </>
    )
}

export default Launchbutton;