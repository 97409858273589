import React from "react";
import Slider from "react-slick";

const Community = () =>{
    return(
        <>
            <section className="py-14 md:py-20 relative px-4">
                <div className="container mx-auto relative z-10">
                    <div className="px-10 md:px-24 py-10 md:py-32 rounded-3xl md:rounded-[150px] bg-[#23F6F6] bg-opacity-0 shadow-effect backdrop-blur-lg">
                        <div className="text-3xl md:text-5xl text-white font-semibold mb-6 md:mb-14 tracking-wider text-center">Metaple Community</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center mt-8 md:mt-24">
                            <div className="colspan-1">
                                <div className="relative text-center">
                                    <img src="images/logo/metaple-icon.png" className="logo-main mx-auto md:w-4/5 animate-pulse" alt="Metaple Logo" />
                                    <img src="images/logo/metaple-logo-text.png" className="logo-main absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-pulse" alt="Metaple Text Logo" />
                                </div> 
                            </div>
                            <div className="colspan-1">
                                <p className="text-white leading-loose">Extremely active community that loves our mission! Our telegram channel is filled with community moderators 24/7 that would love to help you. Do not trust random messages of people claiming to be “support” or ever give out your wallet info to anyone.</p>
                                <div className="grid grid-cols-2 gap-3 md:gap-8 mt-12">
                                    <div className="col-span-1">
                                        <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Telegram group Member</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">31,444+</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Twitter Followers</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">16,200+</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-sky-400 bg-opacity-10 rounded-xl border-2 border-sky-400 border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">Facebook Followers</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="bg-white bg-opacity-10 rounded-xl border-2 border-white border-opacity-20 py-3 md:py-5 px-2 md:px-4 text-center text-white text-xs md:text-sm tracking-wider font-medium h-full backdrop-blur-lg">8,272+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Community;